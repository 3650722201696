<template>
  <div>
    <b-card-actions
      title="Filters"
      action-collapse
      collapsed
    >
      <b-row>
        <b-col
          cols="6"
          md="3"
        >
          <b-form-group
            label="Deposit Date"
            label-for="deposit_date"
          >
            <div class="position-relative">
              <flat-pickr
                v-model="filters['created_at']"
                class="form-control"
                :config="{ mode: 'range',enableTime: true, dateFormat: 'Z', altInput: true, altFormat: 'Y-m-d H:i:s'}"
                placeholder="Select Date"
                :disabled="disabled"
              />
              <div
                v-if="filters['status_date']"
                class="flatpickr-cancel"
                @click="clearDate('status_date')"
              >
                <feather-icon icon="XIcon" />
              </div>
            </div>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="mt-1">
        <b-col>
          <filter-buttons
            :filter-data="filters"
            :apply-filters="applyFilters"
            :clear-filters="clearFilters"
          />
        </b-col>
      </b-row>
    </b-card-actions>
    <b-card
      no-body
    >
      <b-overlay
        :show="submitLoading"
        blur="10px"
        opacity="0.30"
        rounded="sm"
        variant="light"
      >
        <div
          class="m-2"
        >
          <b-row
            cols="12"
            class="pt-1 pb-1"
          >
            <b-col
              class="justify-content-end"
            >
              <b-row
                class="d-flex align-items-center justify-content-end mr-1"
              >
                <b-col class="d-flex justify-content-start">
                  <div
                    class="font-weight-bolder text-muted mobile-margin"
                  >
                    {{ `${depositSelected.length} record${depositSelected.length === 1 ? '':'s'}` }} selected
                  </div>
                </b-col>
                <b-col md="3">
                  <v-select
                    v-model="selectedAgent"
                    :options="Agents"
                    :reduce="option => option.id"
                    label="name"
                    class="ml-1 mobile-margin"
                    placeholder="Select Agent"
                    @input="deselectAll()"
                  >
                    <template v-slot:option="option">
                      {{ option.name }}
                    </template>
                  </v-select>
                </b-col>
                <div>
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    class="custom-button-color-blur mobile-margin  mr-2"
                    @click="loadBills"
                  >
                    Get Deposits
                  </b-button>
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    class="custom-button-color mobile-margin"
                    :disabled="depositSelected.length > 0? false : true"
                    @click="SubmitDeposit"
                  >
                    Submit
                  </b-button>
                </div>
                <div class="ml-1">
                  <ReportTableButtons
                    :items="items"
                    :json_fieldz="json_fields"
                    :name="`${downloadReportName} - Page ${page}.xls`"
                    :bulkname="`${downloadReportName}.xls`"
                    :fetch="fetchDepositsForAgentNoPagination"
                    @refresh="fetchDepositsForAgent"
                  />
                </div>
              </b-row>
            </b-col>
          </b-row>
        </div>
        <div>
          <b-row>
            <b-col md="12">
              <b-overlay
                id="overlay-background"
                blur="10px"
                opacity="0.30"
                rounded="sm"
                variant="light"
              >
                <b-table
                  :per-page="perPage"
                  :items="items"
                  :fields="fields"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :sort-direction="sortDirection"
                  hover
                  responsive
                  striped
                  show-empty
                  class="table-responsive"
                >
                  <template #empty>
                    <TableDataFetching
                      :rows="items"
                      :data-loading="dataLoading"
                    />
                  </template>
                  <template #head(id)>
                    <div class="d-flex align-items-start">
                      <div>
                        <b-form-checkbox
                          id="selectAllCheckbox"
                          v-model="selectAllCheckbox"
                          name="selectAllCheckbox"
                          @change="onClickSelectAllCheckbox()"
                        />
                      </div>
                    </div>
                  </template>
                  <template #cell(id)="data">
                    <div class="d-flex align-items-start">
                      <div>
                        <div class="font-weight-bolder">
                          <b-form-checkbox
                            v-model="depositSelected"
                            :value="data.item.ref_no"
                          />
                        </div>
                      </div>
                    </div>
                  </template>
                  <template #cell(ref_no)="data">
                    <div class="d-flex align-items-center">
                      <b-avatar
                        :text="avatarText(data.item.ref_no)"
                        size="32"
                        variant="light-primary"
                      />
                      <div class="ml-1">
                        <div
                          class="font-weight-bolder text-primary"
                        >
                          {{ data.value }}
                        </div>
                        <div class="font-small-2">
                          {{ __dateTimeFormatter(data.item.created_at) }}
                        </div>
                      </div>
                    </div>
                  </template>
                  <template #cell(depositable_amount)="data">
                    <span>{{ __numberWithCommas(data.value, true) }}</span>
                  </template>
                  <template #cell(deposit_amount)="data">
                    <span>{{ __numberWithCommas(data.value, true) }}</span>
                  </template>
                  <template #cell(expenses_total)="data">
                    <span>{{ __numberWithCommas(data.value, true) }}</span>
                  </template>
                  <template #cell(commission)="data">
                    <span>{{ __numberWithCommas(data.value, true) }}</span>
                  </template>
                  <template #cell(deposited_date)="data">
                    {{ __dateTimeFormatter(data.value) || '---' }}
                  </template>
                  <template #cell(approved_date)="data">
                    {{ __dateTimeFormatter(data.value) || '---' }}
                  </template>
                  <template #cell(finance_current_status.key)="data">
                    <finance-status-badge
                      :status-key="data.value"
                      :finance-status="financeStatusOptions"
                    />
                  </template>

                </b-table>
              </b-overlay>
              <div class="mx-2 mb-2">
                <b-row
                  cols="12"
                >
                  <b-col
                    class="d-flex align-items-center justify-content-sm-start"
                    md="3"
                  >
                    <label class="width-75">Per page</label>
                    <v-select
                      v-model="perPage"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="pageOptions"
                      :clearable="false"
                      class="per-page-selector d-inline-block width-100"
                    />
                  </b-col>
                  <!-- Pagination -->
                  <b-col
                    class="d-flex align-items-center justify-content-sm-end"
                    md="9"
                  >
                    <span class="text-muted pagination-text  mt-1 mt-md-0 mr-1">Showing {{ meta.from }} to {{ meta.to }} of {{ meta.total }} entries</span>
                    <b-pagination
                      v-model="page"
                      :total-rows="(meta.total)? meta.total : 0"
                      :per-page="perPage"
                      first-number
                      last-number
                      class="mb-0 mt-1 mt-sm-0"
                      prev-class="prev-item"
                      next-class="next-item"
                    >
                      <template #prev-text>
                        <feather-icon
                          icon="ChevronLeftIcon"
                          size="18"
                        />
                      </template>
                      <template #next-text>
                        <feather-icon
                          icon="ChevronRightIcon"
                          size="18"
                        />
                      </template>
                    </b-pagination>

                  </b-col>

                </b-row>
              </div>
            </b-col>
          </b-row>
        </div>
      </b-overlay>
    </b-card>
    <commission-detail-modal ref="commission_detail_modal" />
  </div>
</template>

<script>
import {
  BCard,
  BCol,
  BFormGroup,
  BRow,
  VBModal,
  BButton,
  VBTooltip,
  BPagination,
  BFormCheckbox,
  BTable,
  BOverlay,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import FinanceStatusBadge from '@core/components/status/FinanceStatusBadge.vue'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import { heightTransition } from '@core/mixins/ui/transition'
import CommissionDetailModal from '@/components/FinanceModuleForms/InfoModals/CommissionDetailModal.vue'
import SuccessMessage from '@core/mixins/SuccessMessage'
import ErrorMessage from '@core/mixins/ErrorMessage'
import Filter from '@core/mixins/filter'
import { RepositoryFactory } from '@/repository/RepositoryFactory'
import FilterButtons from '@/components/Filter/FilterButtons.vue'

const ResourceRepository = RepositoryFactory.get('resource')
const FinanceRepository = RepositoryFactory.get('finance')

export default {
  directives: {
    'b-tooltip': VBTooltip,
    'b-modal': VBModal,
    Ripple,
  },
  components: {
    BOverlay,
    BCol,
    BFormGroup,
    BFormCheckbox,
    BRow,
    vSelect,
    BCard,
    BButton,
    BPagination,
    BTable,
    BCardActions,
    FinanceStatusBadge,
    CommissionDetailModal,
    flatPickr,
    FilterButtons,
  },
  mixins: [Filter, SuccessMessage, ErrorMessage, heightTransition],
  data() {
    return {
      avatarText,
      filters: {},
      financeStatusOptions: [],
      depositSelected: [],
      options: {
        date: {
          date: true,
          delimiter: '-',
          datePattern: ['Y', 'm', 'd'],
        },
      },
      loading: false,
      dataLoading: false,
      submitLoading: false,
      sort: '',
      perPage: 10,
      pageOptions: [10, 20, 50],
      expenseType: [
        { name: 'Fuel', value: 'fuel' },
        { name: 'Food', value: 'food' },
      ],
      totalRows: 1,
      page: 1,
      meta: {},
      dir: false,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      disabled: true,
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [
        {
          key: 'id',
          label: '#',
          thClass: 'text-left',
          tdClass: 'text-left',
        },
        {
          key: 'ref_no',
          label: 'REF No',
          thClass: 'text-center',
          sortable: true,
        },
        {
          key: 'warehouse.name',
          label: 'Warehouse',
          sortable: true,
        },
        {
          key: 'depositable_amount',
          label: 'Collected COD',
          thClass: 'text-right',
          tdClass: 'text-right',
          sortable: true,
        },
        {
          key: 'expenses_total',
          label: 'Expenses Total',
          thClass: 'text-right',
          tdClass: 'text-right',
          sortable: true,
        },
        {
          key: 'commission',
          label: 'Commission',
          thClass: 'text-right',
          tdClass: 'text-right',
          sortable: true,
        },
        {
          key: 'deposit_amount',
          label: 'Depositable Amount',
          thClass: 'text-right',
          tdClass: 'text-right',
          sortable: true,
        },
        {
          key: 'deposited_date',
          label: 'Deposited Date',
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          key: 'approved_date',
          label: 'Approved Date',
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          key: 'finance_current_status.key',
          label: 'Status',
          thClass: 'text-center',
          tdClass: 'text-center',
          sortable: true,
        },
      ],
      items: [],
      selectedAgent: '',
      Agents: [],
      json_fields: {
        'Ref No': 'ref_no',
        'Deposit Date': {
          field: 'created_at',
          // eslint-disable-next-line no-underscore-dangle
          callback: value => this.__dateTimeFormatter(value),
        },
        Warehouse: {
          field: 'warehouse.name',
          callback: value => `"${value}"`,
        },
        'Depositable Amount': 'depositable_amount',
        'Expenses Total': 'expenses_total',
        Commission: 'commission',
        'Deposit Amount': 'deposit_amount',
        'Deposited Date': {
          field: 'deposited_date',
          // eslint-disable-next-line no-underscore-dangle
          callback: value => this.__dateTimeFormatter(value),
        },
        'Approved Date': {
          field: 'approved_date',
          // eslint-disable-next-line no-underscore-dangle
          callback: value => this.__dateTimeFormatter(value),
        },
        Status: 'finance_current_status.name',
      },
      errorCount: 0,
      selectAllCheckbox: false,
      downloadReportName: 'Agent-New-Commissions',
      pageWithSelectAll: [],
    }
  },
  watch: {
    options: {
      handler() {
        this.fetchDepositsForAgent()
      },
    },
    deep: true,
    page() {
      this.fetchDepositsForAgent()
    },
    perPage() {
      this.fetchDepositsForAgent()
    },
    selectedAgent: {
      async handler(val) {
        if (val === null) {
          await this.clearDate('status_date')
          this.disabled = true
        }
      },
    },
  },
  mounted() {
    this.financeStatusOptions = JSON.parse(localStorage.getItem('finance_statuses'))
    this.readDataforAgency()
  },
  methods: {
    async fetchDepositsForAgent() {
      this.dataLoading = true
      const isChecked = this.pageWithSelectAll.includes(this.page)
      if (!isChecked) {
        this.selectAllCheckbox = false
      } else {
        this.selectAllCheckbox = true
      }
      if (this.selectedAgent !== '') {
        try {
          this.loading = true
          // eslint-disable-next-line no-underscore-dangle
          this.filterQuery = this.__refineDateQuery(this.filterQuery, [this.filters])
          const { data } = (await FinanceRepository.getAllAgentDepositsList(this.page, this.perPage, this.selectedAgent, this.filterQuery))
          this.items = data.data
          this.meta = data.meta
          this.disabled = false
        } catch (e) {
          this.showErrorMessage('Sorry for the inconvenience! Please Try Again!!')
        }
      }
      this.loading = false
      this.dataLoading = false
    },
    async fetchDepositsForAgentNoPagination() {
      try {
        // eslint-disable-next-line no-underscore-dangle
        this.filterQuery = this.__refineDateQuery(this.filterQuery, [this.filters])
        const { data } = (await FinanceRepository.getAllAgentDepositsListNoPagination(this.selectedAgent, this.filterQuery))
        return data.data
      } catch (e) {
        this.convertAndNotifyError(e)
        return []
      }
    },
    async readDataforAgency() {
      try {
        this.Agents = localStorage.getItem('agent_list') ? JSON.parse(localStorage.getItem('agent_list')) : (await ResourceRepository.getAgencyListDropdown()).data.data
        if (!localStorage.getItem('agent_list')) localStorage.setItem('agent_list', JSON.stringify(this.Agents))
      } catch (e) {
        this.convertAndNotifyError(e)
      }
    },
    handleChangePage(page) {
      this.page = page
      this.fetchDepositsForAgent()
    },
    applyFilters() {
      this.fetchDepositsForAgent()
      this.deselectAll()
    },
    clearFilters() {
      this.filters = {}
      this.$nextTick(() => {
        this.fetchDepositsForAgent()
      })
    },
    selectAll() {
      this.depositSelected = this.items.map(n => n.ref_no)
    },
    deselectAll() {
      this.depositSelected = []
      this.selectAllCheckbox = false
      this.items = []
      this.meta = {}
    },
    onClickRefresh() {
      this.fetchDepositsForAgent()
    },
    onClickSelectAllCheckbox() {
      if (this.selectAllCheckbox) {
        // If the "Select All" checkbox is checked, select all waybill numbers.
        this.depositSelected = [...this.depositSelected, ...this.items.map(n => n.ref_no)]
        this.pageWithSelectAll = [...this.pageWithSelectAll, this.page]
      } else {
        this.depositSelected = []
        this.pageWithSelectAll = []
      }
    },
    SubmitDeposit() {
      const deposit = {
        ref_numbers: this.depositSelected,
      }
      if (this.depositSelected.length === 0) {
        this.showErrorMessage('Please Select Deposits First')
      } else if (this.selectedAgent !== this.items[0].warehouse_id) {
        this.showErrorMessage('Selected deposits do not match the selected agent')
      } else {
        this.handleSubmit(deposit)
      }
    },
    async handleSubmit(deposit) {
      this.$refs.commission_detail_modal.openModal(this.depositSelected, this.createDeposit, deposit, 'agent')
    },
    async createDeposit(deposit) {
      const res = await FinanceRepository.createAgentFinanceCommissionInvoice(deposit)
      if (res.status === 200) {
        this.showSuccessMessage('Data Added Successfully')
        await this.$router.push('/operations/agency-commission')
      } else {
        this.showErrorMessage('Cannot Add Commission Invoice')
      }
    },
    async loadBills() {
      if (!this.selectedAgent) {
        this.showErrorMessage('Please Select an Agent')
      } else {
        this.deselectAll()
        await this.fetchDepositsForAgent()
      }
    },
    changePage(value) {
      this.perPage = value
    },
    clearDate(filterKey) {
      this.filters[filterKey] = ''
    },
  },
}
</script>
<style scoped lang="scss">
@import '../../@core/scss/vue/libs/vue-select.scss';
@import '../../@core/scss/vue/libs/vue-flatpicker.scss';
.custom-button-color {
  background-color: #4186f5 !important;
  border-color: #4186f5 !important;
}
.custom-button-color-blur {
  background-color: #6da5ff !important;
  border-color: #6da5ff !important;
}
@media (max-width: 767px) {
  .table-responsive {
    position: sticky;
    top: 0;
  }
  .mobile-margin {
    margin-bottom: 20px;
    width: 100%;
  }
  .pagination-text {
    font-size: 0.75rem;
  }
}
</style>
